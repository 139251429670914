import React, { useState, useEffect, useRef,useMemo } from 'react';
import { Typography, Button, Input, Select, Slider, Tabs, Modal, Table, Checkbox, Spin,message } from 'antd';
import { DownloadOutlined, EditOutlined, UploadOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import styles from './socialMediaCardGenerator.module.scss';
import Utils from '../../../../utils/utils';
import OrgBanner from '../../../OrgBanner/orgbanner';
import Draggable from "react-draggable";
import TextArea from 'antd/lib/input/TextArea';



const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const ColorPicker = ({ color, onChange }) => {
  const handleColorChange = (e) => {
    let newColor = e.target.value;
    if (newColor.charAt(0) !== '#') {
      newColor = '#' + newColor;
    }
    onChange(newColor);
  };

  return (
    <div className={styles.colorPicker}>
      <input type="color" value={color} onChange={handleColorChange} />
      <input
        type="text"
        value={color}
        onChange={handleColorChange}
        onBlur={(e) => {
          let finalColor = e.target.value;
          if (finalColor.charAt(0) !== '#') {
            finalColor = '#' + finalColor;
          }
          onChange(finalColor);
        }}
      />
    </div>
  );
};

const FactBriefLogo = ({orgDetails}) => (
  <div className={styles.logoContainer}>
    <div className={styles.factBriefContent}>
      <div className={styles.factBriefInfo}>
        <img
          src={`${process.env.PUBLIC_URL}/favicon.ico`}
          alt="Info Icon"
          className={styles.infoIcon}
        />
        <span className={styles.factBriefText}>Fact Brief</span>
      </div>
      <div className={styles.orgLogo}>
        <img
          src={orgDetails?.logoURL} 
          alt="Organization Logo"
          className={styles.orgLogoImage}
        />
      </div>
    </div>
  </div>
);
const StandardLogo = ({ orgDetails, configurations }) => (
    <div className={styles.logoContainer}>
      <div className={styles.standardLogoContent}>
        {configurations.logoBox ? (
          <img
            src={orgDetails?.logoURL || ''} // Fallback to an empty string if logoURL is undefined
            alt="Organization Logo"
            className={styles.standardLogoImage}
          />
        ) : null}
      </div>
    </div>
  );
  

const CTALogo = ({ orgDetails }) => (
  <div className={styles.logoContainer}>
    <div className={styles.ctaLogoContent}>
      <img
        src={orgDetails?.tiplineLogoURL}
        alt="CTA Logo"
        className={styles.ctaLogoImage}
      />
    </div>
  </div>
);

const FontSelector = ({ selectedFont, handleFontChange }) => {
  const [fonts, setFonts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFonts = async () => {
      const fontsApiKey = 'AIzaSyA1n_RXhN6MJv_hTzNXF2QlUQ5RixQe5z8';
      try {
        const response = await axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${fontsApiKey}&sort=popularity`);
        setFonts(response.data.items);
      } catch (error) {
        console.error('Error fetching fonts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFonts();
  }, []);

  useEffect(() => {
    if (selectedFont) {
      loadFont(selectedFont);
    }
  }, [selectedFont]);

  const loadFont = (fontFamily) => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css?family=${fontFamily.replace(' ', '+')}`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      placeholder={loading ? 'Loading fonts...' : 'Select a font'}
      optionFilterProp="children"
      value={selectedFont}
      onChange={(value) => {
        handleFontChange(value);
        loadFont(value);
      }}
      filterOption={filterOption}
      loading={loading}
    >
      {fonts.map((font) => (
        <Option key={font.family} value={font.family} style={{ fontFamily: font.family }}>
          {font.family}
        </Option>
      ))}
    </Select>
  );
};

const SocialMediaCardGenerator = () => {

  

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef(null);
  


  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const handleDrag = (e, ui) => {
    const { x, y } = modalPosition;
    setModalPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [orgDetails, setOrgDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [cards, setCards] = useState([
    {
      type: 'question',
      content: 'Lorem ipsum dolor sit amet consectetur?',
      background: '#212121',
      gradient: {
        enabled: false,
        color1: '#000000',
        color2: '#FFFFFF',
        angle: 45,
      },
      textColor: '#FFFFFF',
      fontFamily: 'Arial',
      fontSize: 22,
      fontWeight: 'bold',
    },
    {
      type: 'answer',
      content: 'Yes.',
      description: 'Lorem ipsum dolor sit amet consectetur. Et dui ridiculus lacus nisi at lobortis lectus etiam elit.',
      background: '#FED231',
      gradient: {
        enabled: false,
        color1: '#FED231',
        color2: '#FFA500',
        angle: 45,
      },
      textColor: '#212121',
      fontFamily: 'Arial',
      contentFontSize: 40,
      descriptionFontSize: 18,
      fontWeight: 'bold',
    },
    {
      type: 'details',
      content: 'Lorem ipsum dolor sit amet consectetur. Cras orci duis sapien vulputate cursus gravida diam et nibh. Cras diam vulputate fermentum commodo netus.',
      background: '#212121',
      gradient: {
        enabled: false,
        color1: '#212121',
        color2: '#444444',
        angle: 45,
      },
      textColor: '#FFFFFF',
      fontFamily: 'Arial',
      fontSize: 18,
      fontWeight: 'normal',
    },
    {
      type: 'cta',
      header: 'Curious about the full story?',
      subheader: 'Read the rest on our site.',
      background: '#FFFFFF',
      gradient: {
        enabled: false,
        color1: '#FFFFFF',
        color2: '#EEEEEE',
        angle: 45,
      },
      textColor: '#212121',
      fontFamily: 'Arial',
      headerFontSize: 22,
      subheaderFontSize: 18,
      fontWeight: 'bold',
    },
  ]);
  const [factBriefs, setFactBriefs] = useState([]);
  const [isFactBriefModalVisible, setIsFactBriefModalVisible] = useState(false);
  const [configurations, setConfigurations] = useState({
    backgroundGradient: false,
    swipeTextArrow: true,
    logoBox: true,
  });
  const [editingCard, setEditingCard] = useState(null);
  const [selectedFont, setSelectedFont] = useState('Arial');

  const fileInputRef = useRef(null);

  

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchFactBriefs(pagination.current, pagination.pageSize),
          getOrgsInfo(),
          getSocialMediaConfig()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getSocialMediaConfig = async () => {
    try {
      const api = `${Utils.getAPIDescription('get-social-media-config')}?org_id=${localStorage.getItem('org_id')}`;
      const response = await axios.get(api);
      if (response.data && response.data.config) {
        setCards(response.data.config.cards);
        setConfigurations(response.data.config.configurations);
        const configFont = response.data.config.selectedFont || 'Arial';
        setSelectedFont(configFont);
        loadFont(configFont);
      }
    } catch (error) {
      console.error('Error fetching social media config:', error);
    }
  };

  const loadFont = (fontFamily) => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css?family=${fontFamily.replace(' ', '+')}`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    if (selectedFont && selectedFont !== 'Arial') {
      loadFont(selectedFont);
    }
  }, [selectedFont]);

  const handleSavePreset = async () => {
    try {
      const api = Utils.getAPIDescription('create-social-media-config');
      const defaultContent = {
        question: 'Lorem ipsum dolor sit amet consectetur?',
        answer: 'Yes.',
        answerDescription: 'Lorem ipsum dolor sit amet consectetur. Et dui ridiculus lacus nisi at lobortis lectus etiam elit.',
        details: 'Lorem ipsum dolor sit amet consectetur. Cras orci duis sapien vulputate cursus gravida diam et nibh. Cras diam vulputate fermentum commodo netus.',
      };

      const updatedCards = cards.map(card => {
        if (card.type === 'cta') {
          return {
            ...card,
            header: card.header,
            subheader: card.subheader
          };
        } else {
          return {
            ...card,
            content: defaultContent[card.type] || card.content,
            description: card.type === 'answer' ? defaultContent.answerDescription : card.description
          };
        }
      });

      const payload = {
        org_id: localStorage.getItem('org_id'),
        config: {
          cards: updatedCards,
          configurations,
          selectedFont
        }
      };

      await axios.post(api, payload);
      message.success('Preset saved successfully!', 3)
    } catch (error) {
      console.error('Error saving preset:', error);
      message.error('Failed to save preset. Please try again.', 3);
    }
  };

  const fetchFactBriefs = (page, pageSize) => {
    setIsLoadingPage(true);
    // let api = process.env.REACT_APP_ENV !== 'prod'
    //   ? 'https://dev-api.gigafact.org/partner-console/org_reviews_paginated'
    //   : 'https://api.gigafact.org/partner-console/org_reviews_paginated';

    let api = "https://api.gigafact.org/partner-console/org_reviews_paginated"

    return axios.get(api, {
      params: {
        orgslug: JSON.parse(localStorage.getItem('org-storage'))?.slug,
        type: 'organization',
        page_size: pageSize,
        after: (page - 1) * pageSize,
        timeframe: 'all'
      }
    })
    .then(response => {
      setFactBriefs(response.data.body.paginatedReviewsList);
      setPagination({
        ...pagination,
        current: page,
        total: 322,
      });
    })
    .catch(error => console.error('Error fetching fact briefs:', error))
    .finally(() => {
      setIsLoadingPage(false);
    });
  };

  const getOrgsInfo = () => {
    const api = `${Utils.getAPIDescription('get-org-details')}?org_id=${localStorage.getItem('org_id')}`;
        return axios
      .get(api)
      .then((res) => {
        if (res.data?.data) {
          setOrgDetails(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCardProperty = (index, property, value) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index] = { ...newCards[index], [property]: value };
      return newCards;
    });
  };

  const handleLoadFactBrief = () => {
    setIsFactBriefModalVisible(true);
  };

  const handleTableChange = (pagination) => {
    fetchFactBriefs(pagination.current, pagination.pageSize);
  };

  const handleSelectBrief = (brief) => {
    setIsFactBriefModalVisible(false);
    
    const parser = new DOMParser();
    const analysisDoc = parser.parseFromString(brief.analysis, 'text/html');
    const paragraphs = analysisDoc.querySelectorAll('p');
    const firstSentence = paragraphs[0]?.textContent || '';
    const secondSentence = paragraphs[1]?.textContent || '';

    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[0] = { ...newCards[0], content: brief.title };
      newCards[1] = { ...newCards[1], content: brief.brief_answer, description: firstSentence };
      newCards[2] = { ...newCards[2], content: secondSentence };
      return newCards;
    });
  };

  const handleDownload = async () => {
    const zip = new JSZip();

    for (let i = 0; i < cards.length; i++) {
      try {
        const cardElement = document.getElementById(`card-${i}`);
        if (!cardElement) {
          console.error(`Card element ${i + 1} not found`);
          continue;
        }

        const canvas = await html2canvas(cardElement, {
          scale: 4, // Upscale 4x
          useCORS: true,
          logging: false,
        });

        const imageData = canvas.toDataURL('image/png');
        zip.file(`card_${i + 1}.png`, imageData.split('base64,')[1], {base64: true});
      } catch (error) {
        console.error(`Error capturing card ${i + 1}:`, error);
      }
    }

    try {
      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, 'social_media_cards.zip');
    } catch (error) {
      console.error('Error generating zip file:', error);
    }
  };

  const handleLoadPreset = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const preset = JSON.parse(e.target.result);
          setCards(preset.cards);
          setConfigurations(preset.configurations);
          setSelectedFont(preset.selectedFont);
        } catch (error) {
          console.error('Error parsing preset file:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleFontChange = (newFont) => {
    setSelectedFont(newFont);
    setCards(prevCards => prevCards.map(card => ({ ...card, fontFamily: newFont })));
    loadFont(newFont);
  };

  const renderCardPreview = (card, index) => {
    const getLineHeight = (fontSize) => {
      return Math.max(fontSize * 1.5, 24); // Ensure minimum line height of 24px
    };

    const getBackgroundStyle = (card) => {
      if (card.gradient.enabled) {
        return {
          background: `linear-gradient(${card.gradient.angle}deg, ${card.gradient.color1}, ${card.gradient.color2})`,
        };
      }
      return { backgroundColor: card.background };
    };

    const renderLogo = () => {
      if (card.type === 'question') return <FactBriefLogo orgDetails={orgDetails}/>;
      if (card.type === 'answer') return <StandardLogo orgDetails={orgDetails} configurations={configurations}/>;
      if (card.type === 'details') return <StandardLogo orgDetails={orgDetails} configurations={configurations}/>;
      if (card.type === 'cta') return <CTALogo orgDetails={orgDetails}/>;
      return null;
    };

    const getContentStyle = () => {
      if ((card.type === 'answer' || card.type === 'details') && !configurations.logoBox) {
        return { 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          height: '100%',
          paddingTop: '0'
        };
      }
      return {};
    };

    return (
      <div key={index} className={styles.cardContainer}>
        <div 
          id={`card-${index}`}
          className={styles.cardPreview} 
          style={{ 
            ...getBackgroundStyle(card),
            color: card.textColor,
            fontFamily: card.fontFamily,
          }}
        >
          <div className={`${styles.cardSegment} ${styles.topSegment}`}>
            {renderLogo()}
          </div>
          <div 
            className={`${styles.cardSegment} ${styles.middleSegment}`}
            style={getContentStyle()}
          >
            {card.type === 'question' && (
              <Title level={2} style={{ 
                fontSize: card.fontSize, 
                fontWeight: card.fontWeight, 
                color: card.textColor,
                lineHeight: `${getLineHeight(card.fontSize)}px`
              }}>{card.content}</Title>
            )}
            {card.type === 'answer' && (
              <>
                <Text className={styles.answerText} style={{ 
                  fontSize: card.contentFontSize, 
                  fontWeight: card.fontWeight, 
                  color: card.textColor,
                  lineHeight: `${getLineHeight(card.contentFontSize)}px`
                }}>{card.content}</Text>
                <hr className={styles.divider} />
                <Text style={{ 
                  fontSize: card.descriptionFontSize, 
                  color: card.textColor,
                  lineHeight: `${getLineHeight(card.descriptionFontSize)}px`
                }}>{card.description}</Text>
              </>
            )}
            {card.type === 'details' && (
              <div className={styles.detailsContainer}>
                <div className={styles.verticalBar}></div>
                <Text style={{ 
                  fontSize: card.fontSize, 
                  fontWeight: card.fontWeight, 
                  color: card.textColor,
                  lineHeight: `${getLineHeight(card.fontSize)}px`
                }}>{card.content}</Text>
              </div>
            )}
            {card.type === 'cta' && (
              <>
                <Title level={2} style={{ 
                  fontSize: card.headerFontSize, 
                  fontWeight: card.fontWeight, 
                  color: card.textColor,
                  lineHeight: `${getLineHeight(card.headerFontSize)}px`
                }}>{card.header}</Title>
                <Text style={{ 
                  fontSize: card.subheaderFontSize, 
                  color: card.textColor,
                  lineHeight: `${getLineHeight(card.subheaderFontSize)}px`
                }}>{card.subheader}</Text>
                <div
                  style={{
                    margin: '8px 0',
                    borderBottom: `1px solid ${card.textColor}`,
                  }}
                />
              </>
            )}
          </div>
          <div className={`${styles.cardSegment} ${styles.bottomSegment}`}>
            {configurations.swipeTextArrow && card.type === 'question' && (
              <div className={styles.swipeTextContainer}> 
                <Text className={styles.swipeText} style={{ color: card.textColor }}>Swipe to find out </Text>
                <Text style={{ color: card.textColor }}>→</Text>
              </div>
            )}
          </div>
        </div>
        <Button icon={<EditOutlined />} onClick={() => setEditingCard(index)}>Edit Card</Button>
      </div>
    );
  };

  const renderEditCardModal = () => (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (draggleRef.current) {
              draggleRef.current.style.cursor = 'move';
            }
          }}
          onMouseOut={() => {
            if (draggleRef.current) {
              draggleRef.current.style.cursor = 'auto';
            }
          }}
        >
          Edit Card {editingCard + 1}
        </div>
      }
      visible={editingCard !== null}
      onCancel={() => setEditingCard(null)}
      footer={null}
      modalRender={(modal) => (
        <Draggable
          bounds={bounds}
          onStart={(event, uiData) => {
            // Only start dragging if the mouse is on the title
            const isOnTitle = event.target.closest('.ant-modal-header');
            if (!isOnTitle) return false;
            onStart(event, uiData);
          }}
          disabled={false}
          handle=".ant-modal-header" // This ensures dragging only works on the header
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      wrapClassName={styles.draggableModal}
    >
      {cards[editingCard]?.type !== 'cta' && (
        <TextArea
          value={cards[editingCard]?.content}
          onChange={(e) => updateCardProperty(editingCard, 'content', e.target.value)}
          placeholder="Card Content"
        />
      )}
      {cards[editingCard]?.type === 'answer' && (
        <>
          <TextArea
            value={cards[editingCard]?.description}
            onChange={(e) => updateCardProperty(editingCard, 'description', e.target.value)}
            placeholder="Description"
          />
          <div>
            <label>Yes/No Font Size:</label>
            <Slider
              min={12}
              max={48}
              value={cards[editingCard]?.contentFontSize}
              onChange={(value) => updateCardProperty(editingCard, 'contentFontSize', value)}
            />
          </div>
          <div>
            <label>First Sentence Font Size:</label>
            <Slider
              min={12}
              max={48}
              value={cards[editingCard]?.descriptionFontSize}
              onChange={(value) => updateCardProperty(editingCard, 'descriptionFontSize', value)}
            />
          </div>
        </>
      )}
      {cards[editingCard]?.type === 'cta' && (
        <>
          <Input
            value={cards[editingCard]?.header}
            onChange={(e) => updateCardProperty(editingCard, 'header', e.target.value)}
            placeholder="Header"
          />
          <Input
            value={cards[editingCard]?.subheader}
            onChange={(e) => updateCardProperty(editingCard, 'subheader', e.target.value)}
            placeholder="Subheader"
          />
          <div>
            <label>Header Font Size:</label>
            <Slider
              min={12}
              max={48}
              value={cards[editingCard]?.headerFontSize}
              onChange={(value) => updateCardProperty(editingCard, 'headerFontSize', value)}
            />
          </div>
          <div>
            <label>Subheader Font Size:</label>
            <Slider
              min={12}
              max={48}
              value={cards[editingCard]?.subheaderFontSize}
              onChange={(value) => updateCardProperty(editingCard, 'subheaderFontSize', value)}
            />
          </div>
        </>
      )}
      {(cards[editingCard]?.type === 'question' || cards[editingCard]?.type === 'details') && (
        <div>
          <label>Font Size:</label>
          <Slider
            min={12}
            max={48}
            value={cards[editingCard]?.fontSize}
            onChange={(value) => updateCardProperty(editingCard, 'fontSize', value)}
          />
        </div>
      )}
      <Select
        value={cards[editingCard]?.fontWeight}
        onChange={(value) => updateCardProperty(editingCard, 'fontWeight', value)}
        style={{ width: '100%', marginTop: '10px' }}
      >
        <Option value="normal">Normal</Option>
        <Option value="bold">Bold</Option>
      </Select>
      <Checkbox
        checked={cards[editingCard]?.gradient.enabled}
        onChange={(e) => updateCardProperty(editingCard, 'gradient', { ...cards[editingCard].gradient, enabled: e.target.checked })}
      >
        Enable Gradient
      </Checkbox>
      {cards[editingCard]?.gradient.enabled ? (
        <>
          <div>
            <label>Gradient Color 1:</label>
            <ColorPicker
              color={cards[editingCard]?.gradient.color1}
              onChange={(color) => updateCardProperty(editingCard, 'gradient', { ...cards[editingCard].gradient, color1: color })}
            />
          </div>
          <div>
            <label>Gradient Color 2:</label>
            <ColorPicker
              color={cards[editingCard]?.gradient.color2}
              onChange={(color) => updateCardProperty(editingCard, 'gradient', { ...cards[editingCard].gradient, color2: color })}
            />
          </div>
          <div>
            <label>Gradient Angle:</label>
            <Slider
              min={0}
              max={360}
              value={cards[editingCard]?.gradient.angle}
              onChange={(value) => updateCardProperty(editingCard, 'gradient', { ...cards[editingCard].gradient, angle: value })}
            />
          </div>
        </>
      ) : (
        <div>
          <label>Background Color:</label>
          <ColorPicker
            color={cards[editingCard]?.background}
            onChange={(color) => updateCardProperty(editingCard, 'background', color)}
          />
        </div>
      )}
      <div>
        <label>Text Color:</label>
        <ColorPicker
          color={cards[editingCard]?.textColor}
          onChange={(color) => updateCardProperty(editingCard, 'textColor', color)}
        />
      </div>
    </Modal>
  );

  return (
    <div className={styles.cardGenerator}>
      <OrgBanner orgdetails={orgDetails} />
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.header}>
          <div className={styles.instruction}>
          <p >Customize your template to your specifications using the below tools. To save a set of images with a fact brief’s text, click the “Load Fact Brief” button and select the one of interest. After this has been populated, you can use “Download Images” to save the rendered template to your device. If you’d like to save the design you’ve created, you can use the “Save Preset” button so that it will always populate with your specified settings.
</p> 
          <p>If you're unsure of your newsroom's hex color codes, you can use <a href="https://redketchup.io/color-picker" target="_blank">this link</a> to upload an image and extract the main colors. We also recommend ensuring your newsroom's logo and banner are tightly cropped. If you need to edit these images, they can be adjusted by uploading new images in the console's settings.</p>
          </div>
          
            <div className={styles.actions}>
              <Button type="primary" onClick={handleLoadFactBrief}>Load Fact Brief</Button>
              <Button icon={<DownloadOutlined />} onClick={handleDownload}>Download Images</Button>
              <Button icon={<SaveOutlined />} onClick={handleSavePreset}>Save Preset</Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleLoadPreset}
                style={{ display: 'none' }}
                accept=".json"
              />
            </div>
          </div>
          <Tabs defaultActiveKey="1" className={styles.customizationTabs}>
            <TabPane tab="Configurations" key="1">
              <Checkbox
                checked={configurations.swipeTextArrow}
                onChange={(e) => setConfigurations({...configurations, swipeTextArrow: e.target.checked})}
              >
                Swipe Text & Arrow
              </Checkbox>
              <Checkbox
                checked={configurations.logoBox}
                onChange={(e) => setConfigurations({...configurations, logoBox: e.target.checked})}
              >
                Logo Box
              </Checkbox>
            </TabPane>
            {/* <TabPane tab="Call-To-Action" key="2">
              <Input
                placeholder="Call to Action Header"
                value={cards[3].header}
                onChange={(e) => updateCardProperty(3, 'header', e.target.value)}
              />
              <Input
                placeholder="Call to Action Byline"
                value={cards[3].subheader}
                onChange={(e) => updateCardProperty(3, 'subheader', e.target.value)}
              />
            </TabPane> */}
            <TabPane tab="Font" key="3">
            <FontSelector
          selectedFont={selectedFont}
          handleFontChange={handleFontChange}
        />

            </TabPane>
          </Tabs>
 
          <div className={styles.cardPreviews}>
            {cards.map((card, index) => renderCardPreview(card, index))}
          </div>
          <Modal
            title="Select a Fact Brief"
            visible={isFactBriefModalVisible}
            onCancel={() => setIsFactBriefModalVisible(false)}
            footer={null}
            width="80%"
          >
            <Table
              dataSource={factBriefs}
              onChange={handleTableChange}
              pagination={pagination}
              loading={isLoadingPage}
              columns={[
                {
                  title: 'Date',
                  dataIndex: 'firstPublishedAt',
                  key: 'date',
                  render: (text) => new Date(text).toLocaleDateString()
                },
                {
                  title: 'Title',
                  dataIndex: 'title',
                  key: 'title',
                },
                {
                  title: 'Action',
                  key: 'action',
                  render: (_, record) => (
                    <Button onClick={() => handleSelectBrief(record)}>Select</Button>
                  ),
                },
              ]}
              rowKey="pageId"
            />
          </Modal>
          {renderEditCardModal()}
        </>
      )}
    </div>
  );
};

export default SocialMediaCardGenerator;